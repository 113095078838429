import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/users.css";
import "bootstrap/dist/css/bootstrap.min.css";

import moment from "moment";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdArrowDropright,
} from "react-icons/io";
import { ImEqualizer2 } from "react-icons/im";
import { BiEditAlt } from "react-icons/bi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import empty_member from "../assets/images/empty_member.png";

const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  { value: "Other", label: "Other" },
];

const Members = (props) => {
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("AdminProduct", props);
  const [open, setOpen] = useState(false);

  //   const [period, setPeriod] = useState();
  const [status, setStatus] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [allmembers, setallMembers] = useState([]);
  const [members, SetMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("loading", loading);
  const [refresh, setRefresh] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [editData, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);

  console.log("page number , ", page);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const total = allmembers.length;
    let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    const endIndex = startIndex + rowsPerPage;
    const data = allmembers.slice(startIndex, endIndex);

    SetMembers([...data]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allmembers.slice(startIndex, endIndex);

    SetMembers([...data]);
  };

  useEffect(() => {
    async function fetchMembers() {
      try {
        const response = await axios.post(
          Global.base_url + "/user/member",
          null,
          config
        );
        // console.log(response);

        if (response.status === 200) {
          setLoading(false);
          let datatest = response.data.data;
          const total = datatest.length;
          let startIndex = (page + 1) * rowsPerPage - rowsPerPage;

          const endIndex = startIndex + rowsPerPage;
          const data = datatest.slice(startIndex, endIndex);
          console.log("products response: ", response);
          setallMembers(response.data.data);
          SetMembers(data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("admin product error", error);
            return;
          }
        }
      }
    }
    fetchMembers();
  }, [refresh]);
  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };
  // editData.data = members[editData.index];
  // function filter(v) {
  //   var data = [];
  //   data =
  //     v.value !== "all"
  //       ? allmembers.filter((h) => {
  //           // console.log(h);

  //           return h.status === v.value;
  //         })
  //       : allmembers;

  //   return SetMembers(data);
  // }
  // function filterProducts(name) {
  //   var data = [];

  //   data = allmembers.filter((h) => {
  //     if (h.test_type === "Walk In")
  //       return `pidw${h.product_id}`.includes(name.toLowerCase());
  //     if (h.test_type === "Home Test")
  //       return `pidht${h.product_id}`.includes(name.toLowerCase());
  //     if (h.test_type === "Kit Delivery")
  //       return `piddk${h.product_id}`.includes(name.toLowerCase());
  //   });
  //   // console.log("data", data);

  //   return data.length ? SetMembers(data) : null;
  // }
  const initialValues =
    editData.index !== ""
      ? {
          member_id: editData.data.member_id,
          firstname: editData.data.firstname,
          lastname: editData.data.lastname,
          gender: { value: editData.data.gender, label: editData.data.gender },
          dob: moment(editData.data.dob, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").format(
            "YYYY-MM-DD"
          ),
          relationship: editData.data.relationship,
        }
      : {
          firstname: "",
          lastname: "",
          dob: new Date(),
          gender: null,
          relationship: "family",
        };

  function MyVerticallyCenteredModal({
    editData,
    setRefresh,
    initialValues,
    setEdit,
    modalStatus,
  }) {
    const required = <span style={{ color: "red" }}>*</span>;
    const [cardSelect, setCard] = useState(0);
    const inactiveClass = "vaccine-inactivecard-container";
    const [loading, setloading] = useState(false);
    // console.log("Vaccine", vaccine);
    // console.log("Users", user);
    const today = moment().toDate();
    console.log(today);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const config2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const [uploader, setUploader] = useState(false);
    console.log("editData", editData);
    function closeModal() {
      setEdit({
        data: "",
        index: "",
      });
      setShowEditModal(false);
    }
    const deleteMember = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/user/member/delete",
          {
            member_id: editData.data.member_id,
          },
          config2
        );

        // console.log(response);

        if (response.status === 200) {
          await closeModal();
          toast.success("Member Deleted Successfully");

          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Member Deletion failed");
        }
        // return console.log("admin delete fetch error", error);
      }
    };

    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="boldFont">
            <p className="boldFont"></p>
            {editData.index !== "" ? "Edit Member" : "Add Member"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            initialValues={initialValues}
            validationSchema={yup.object().shape({
              relationship: yup.string().required(),
              firstname: yup.string().required("Firstname cannot be empty"),
              lastname: yup.string().required("Lastname cannot be empty"),
              gender: yup.object().required("Please select Gender").nullable(),
              dob: yup
                .date()
                .required("Date of birth cannot be empty")
                .nullable(),
            })}
            onSubmit={async (values, formik) => {
              setUploader(true);

              const url =
                editData.index !== ""
                  ? Global.base_url + "/user/member/update"
                  : Global.base_url + "/user/member/add";

              const data =
                editData.index !== ""
                  ? {
                      member_id: editData.data.member_id,
                      firstname: values.firstname,
                      lastname: values.lastname,
                      gender: values.gender.value,
                      dob: moment(values.dob).format("YYYY-MM-DD"),
                      relationship: values.relationship,
                      //   offer_text: values.product_yellowcolortest,
                    }
                  : {
                      firstname: values.firstname,
                      lastname: values.lastname,
                      gender: values.gender.value,
                      dob: moment(values.dob).format("YYYY-MM-DD"),
                      relationship: values.relationship,
                      //   offer_text: values.product_yellowcolortest,
                    };

              console.log("Daatatat", data);
              // return;

              try {
                const result = await axios.post(url, data, config2);
                // console.log(result);
                if (result.status === 200) {
                  setUploader(false);
                  await closeModal();
                  editData.index !== ""
                    ? toast.success("Member Updated Successfully")
                    : toast.success("Member Added Successfully");
                  // toast.success("User Added Successfully");
                  return setRefresh(Date.now() + 1);
                }
              } catch (error) {
                if (
                  error.response &&
                  error.response.status === 401 &&
                  error.response.status !== undefined
                ) {
                  setUploader(false);
                  return toast.error("Member not added");
                }
                if (error.response && error.response.status === 403) {
                  setUploader(false);
                  editData.index !== ""
                    ? toast.error("member update failed!")
                    : toast.error("Member not added");
                }
              }
            }}
          >
            {(formik) => {
              return (
                <div className="container">
                  <div className="row mt-2">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <p
                        htmlFor="relationship"
                        className="semiFont login__label mb-2"
                      >
                        Relationship{required}
                      </p>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input user__radio"
                          type="radio"
                          name="relationship"
                          id="family"
                          value="family"
                          checked={formik.values.relationship === "family"}
                          onChange={(e) => {
                            formik.setValues({
                              relationship: "family",
                              firstname: "",
                              lastname: "",
                              dob: new Date(),
                            });
                          }}
                        />
                        <label
                          className="form-check-label vaccineform-radio-label mediumFont login__label"
                          htmlFor="family"
                        >
                          Family
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input user__radio"
                          type="radio"
                          name="relationship"
                          id="friend"
                          value="friend"
                          checked={formik.values.relationship === "friend"}
                          onChange={() => {
                            formik.setValues({
                              relationship: "friend",
                              firstname: "",
                              lastname: "",
                              dob: new Date(),
                            });
                          }}
                        />
                        <label
                          className="form-check-label vaccineform-radio-label mediumFont login__label"
                          htmlFor="friend"
                        >
                          Friend
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="firstname"
                          className="semiFont login__label"
                        >
                          First Name{required}
                        </label>
                        <input
                          type="text"
                          value={formik.values.firstname}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "firstname",
                              e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                            )
                          }
                          id="firstname"
                          placeholder="First Name"
                          style={{
                            border: formik.errors.firstname
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          // style={{
                          //   border: formik.errors.firstname
                          //     ? "1px solid red"
                          //     : "1px solid #d2d2d2",
                          //   outline: 0,
                          //   boxShadow: formik.errors.firstname
                          //     ? "none"
                          //     : "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                          // }}
                        />
                        {formik.errors.firstname && (
                          <p className="err" style={{ color: "#dc3545" }}>
                            {formik.errors.firstname}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="lastname"
                          className="semiFont login__label"
                        >
                          Last Name{required}
                        </label>
                        <input
                          type="text"
                          value={formik.values.lastname}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "lastname",
                              e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                            )
                          }
                          style={{
                            border: formik.errors.lastname
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          id="lastname"
                          placeholder="Last Name"
                        />
                        {formik.errors.lastname && (
                          <p className="err" style={{ color: "#dc3545" }}>
                            {formik.errors.lastname}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="gender"
                          className="semiFont login__label"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          Gender {required}
                        </label>
                        <Select
                          value={formik.values.gender}
                          onChange={(p) => {
                            formik.setFieldValue("gender", p);
                          }}
                          id="inputDropdown"
                          placeholder="Select Gender"
                          options={genderOptions}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "8px",
                              border: formik.errors.gender
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                          className="admin__Addproduct--dropdown "
                        />
                        {formik.errors.gender && (
                          <div className="err">
                            <p>{formik.errors.gender}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-12">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: "-5px",
                          marginBottom: "15px",
                        }}
                      >
                        <label htmlFor="dob" className="semiFont login__label">
                          Date of birth{required}
                        </label>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            disableFuture={true}
                            // value={formik.values.dob}
                            // onChange={formik.handleChange("dob")}

                            value={formik.values.dob}
                            onChange={(date) => {
                              formik.setFieldValue("dob", date);

                              console.log("date", date);
                              // handleDateChange();
                            }}
                            animateYearScrolling
                          />
                        </MuiPickersUtilsProvider>

                        {formik.errors.dob && (
                          <p className="err" style={{ color: "#dc3545" }}>
                            {formik.errors.dob}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      editData.index !== ""
                        ? "mt-3 d-flex flex-column flex-sm-row justify-content-between"
                        : "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                    }
                  >
                    {editData.index !== "" ? (
                      <div className="form-group  addproduct__button--align">
                        <Button
                          color="secondary"
                          onClick={deleteMember}
                          style={{
                            backgroundColor: "#f50057",
                            color: "#fff",
                            padding: "10px 15px",
                            textTransform: "initial",
                          }}
                          // hidden={editData.index !== "" ? false : true}
                        >
                          Delete Member
                        </Button>
                      </div>
                    ) : null}
                    <div className="d-flex flex-sm-row addproduct__button--aligncenter ">
                      <div className="form-group ">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={formik.handleSubmit}
                          disabled={!formik.dirty || uploader}
                          style={{
                            backgroundColor: !formik.dirty ? "#777" : "#1565d8",
                            color: "#fff",
                            padding: uploader ? "7px 40px" : "10px 15px",
                            textTransform: "initial",
                            marginRight: "10px",
                          }}
                        >
                          {uploader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Update/Save"
                          )}
                        </Button>
                      </div>
                      <div className="form-group ml-xl-1  ">
                        <Button
                          style={{
                            backgroundColor: "#fff",
                            color: "#1565d8",
                            border: "1px solid #1565d8",
                            padding: "10px 15px",
                            width: "100px",
                            textTransform: "initial",
                          }}
                          onClick={() => {
                            setEdit({
                              data: "",
                              index: "",
                            });
                            closeModal();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="report__container">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <MyVerticallyCenteredModal
            editData={editData}
            initialValues={initialValues}
            setRefresh={setRefresh}
            setEdit={setEdit}
            // modalStatus={props.modalStatus}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          />
          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Members
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search Member"
                />
              </div>
              <Button
                variant="contained"
                color="secondary"
                className="primary__button"
                type="submit"
                startIcon={
                  <IconContext.Provider
                    value={{
                      color: "#fff",
                      size: "25px",
                    }}
                  >
                    <MdAdd />
                  </IconContext.Provider>
                }
                style={{ width: "250px" }}
                className={[classes.rootAbsolute, "primary__button"]}
                onClick={() => {
                  setEdit({
                    data: "",
                    index: "",
                  });
                  setShowEditModal(true);
                }}
              >
                Add Member
              </Button>
              {/* <div>
                  <Select
                    value={status}
                    onChange={(v) => {
                      filter(v);
                      setStatus(v);
                    }}
                    placeholder="Status"
                    options={statusfilterOptions}
                    className="ub-dropdown-periods ub-filter_input-small"
                  />
                </div> */}
              {/* </div> */}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable m-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    Member Id
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    First Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Last Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-price-col"
                  >
                    DOB
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Relationship
                  </th>
                  <th
                    scope="col"
                    // colSpan={2}
                    className="admin-product-row-action-col admin-product-row-table-header"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {members.length > 0 ? (
                  members.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;
                    const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.product_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            MID{data.member_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.firstname}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.lastname}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {moment(data.dob).format("LL")}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ ...rowdataAlign }}
                          >
                            {data.relationship}
                          </td>
                          <td
                            className="admin-product-row-action-col admin-product-row-table-row-data "
                            // colSpan={2}
                            style={rowdataAlign}
                          >
                            <Button
                              key={"view" + i}
                              variant="contained"
                              color="default"
                              className={"admin-product-Filter__button mr-2"}
                              // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                              style={{ padding: "10px 33px" }}
                              onClick={() => {
                                // toast.success("Hello");
                                setEdit({
                                  data: data,
                                  index: i,
                                });
                                setShowEditModal(true);
                              }}
                              startIcon={<BiEditAlt />}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.member_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul className="admin-product-row-table-hide-list text-left ">
                              <li
                                key={data.member_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Last Name :
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.lastname}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={i + data.member_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    DOB :
                                  </div>
                                  <div className="col-6  text-left">
                                    {moment(data.dob).format("YYYY MM DD")}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.member_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Relationship :
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.relationship}
                                  </div>
                                </div>
                              </li>

                              <li
                                key={data.member_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-center mt-2 ml-3">
                                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                    <Button
                                      key={i}
                                      variant="contained"
                                      color="default"
                                      className={
                                        "admin-product-Filter__button mr-2"
                                      }
                                      // startIcon={
                                      //   <AiOutlineCloudDownload color="#2C3E50" />
                                      // }
                                      style={{ padding: "10px 33px" }}
                                      onClick={() => {
                                        setEdit({
                                          data: data,
                                          index: i,
                                        });
                                        setShowEditModal(true);
                                      }}
                                      startIcon={<BiEditAlt />}
                                      // onClick={() => {
                                      //   setBillView({ isView: true, data });
                                      // }}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={empty_member} alt="Empty Member" />
                    <p
                      className="header__txt mt-2"
                      style={{ fontSize: "20px" }}
                    >
                      Members not found
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {allmembers.length > 5 || page > 0 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={allmembers.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Members;
