import { useEffect, useState } from "react";
import "../assets/css/activation.css";
import LoginLogo from "../assets/images/healthmo.png";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Button } from "@material-ui/core";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import Global from "../global";
import { ToastContainer, toast } from "react-toastify";
import { IconContext } from "react-icons";
import axios from "axios";
import { useHistory } from "react-router";
const AccountActivation = () => {
  const history = useHistory();
  const [securetext, setSecuretext] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(window.location.pathname.split("/activation/")[1]);
  }, []);

  //   console.log("hash", window.location.pathname.split("/activation/")[1]);
  return (
    <div className="activation__container center__items p-3">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="password__container p-3">
        <div className="activation__header">
          {/* <div className="center__items"> */}
          <img src={LoginLogo} alt="Ampath logo" className="login__logo" />
          {/* </div> */}
          {/* Account activations */}
          {/* <h3 className="activation__title mt-2">
          Hello <span className="header__span">test@gmail.com</span>
        </h3> */}
          <h2
            className="hkBoldFont login__header"
            style={{ marginTop: "10px" }}
          >
            Password Setup
          </h2>
          <div className="form__container mt-3">
            <Formik
              validateOnChange={false}
              validateOnMount={true}
              validateOnBlur={false}
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={yup.object().shape({
                newPassword: yup
                  .string()
                  .min(8, "Password atleast 8 letters")
                  .max(15),
                confirmPassword: yup
                  .string()
                  .when("newPassword", (newPassword) => {
                    if (newPassword) {
                      return yup
                        .string()
                        .required("Confirm password cannot be empty")
                        .oneOf(
                          [yup.ref("newPassword")],
                          "Confirm password must be same as new password"
                        )
                        .min(8)
                        .max(15);
                    }
                  }),
              })}
              onSubmit={async (values, formik) => {
                setSubmitLoader(true);
                console.log(values);

                // return;

                try {
                  const response = await axios.post(
                    Global.base_url + "/user/activation",
                    {
                      newPassword: values.newPassword,
                      confirmPassword: values.confirmPassword,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                    //   config
                  );

                  // console.log(response);
                  if (response.status === 200) {
                    setSubmitLoader(false);
                    let interval = setInterval(() => {
                      history.replace({ pathname: "/" });
                      clearInterval(interval);
                    }, 3000);

                    toast.success("Account activated");
                    //   return setRefresh(Date.now() + 1);
                  }

                  //   return;
                } catch (error) {
                  setSubmitLoader(false);
                  if (error.response && error.response.status === 401) {
                    return toast.error(error.response.data.msg);
                  }
                  if (error.response && error.response.status === 500) {
                    return toast.error("Internal server error");
                  }
                  // console.log("error", error);
                }
              }}
            >
              {(formik) => {
                return (
                  <>
                    <div
                      className="inputBox"
                      style={{
                        position: "relative",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor="newPassword"
                        className="semiFont login__label"
                      >
                        Password
                      </label>
                      <input
                        type={securetext ? "password" : "text"}
                        maxLength={15}
                        value={formik.values.newPassword}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "newPassword",
                            e.currentTarget.value.replace(/[^a-zA-Z0-9@]/g, "")
                          );
                        }}
                        style={{
                          border: formik.errors.newPassword
                            ? "1px solid red"
                            : "1px solid #c3cad9",
                          marginTop: "5px",
                          width: "300px",
                        }}
                        id="newPassword"
                        aria-describedby="newPassword"
                        placeholder="New password"
                      />
                      {/* <div
                                  className="signuppwd__icon"
                                  onClick={(e) =>
                                    securetext
                                      ? setSecuretext(false)
                                      : setSecuretext(true)
                                  }
                                >
                                  <IconContext.Provider
                                    value={{
                                      color: "#959EAD",
                                      size: "20px",
                                    }}
                                  >
                                    {securetext ? <VscEye /> : <VscEyeClosed />}
                                  </IconContext.Provider>
                                </div> */}

                      {formik.errors.newPassword && (
                        <p className="err">{formik.errors.newPassword}</p>
                      )}
                    </div>
                    <div
                      className="inputBox"
                      style={{
                        position: "relative",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor="confirmPassword"
                        className="semiFont login__label"
                      >
                        Confirm Password
                      </label>
                      <input
                        type={securetext ? "password" : "text"}
                        maxLength={15}
                        value={formik.values.confirmPassword}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "confirmPassword",
                            e.currentTarget.value.replace(/[^a-zA-Z0-9@]/g, "")
                          );
                        }}
                        style={{
                          border: formik.errors.confirmPassword
                            ? "1px solid red"
                            : "1px solid #c3cad9",
                          marginTop: "5px",
                          width: "300px",
                        }}
                        id="confirmPassword"
                        aria-describedby="confirmPassword"
                        placeholder="Confirm passsword"
                      />
                      <div
                        className="signuppwd__icon"
                        onClick={(e) =>
                          securetext
                            ? setSecuretext(false)
                            : setSecuretext(true)
                        }
                      >
                        <IconContext.Provider
                          value={{
                            color: "#959EAD",
                            size: "20px",
                          }}
                        >
                          {securetext ? <VscEye /> : <VscEyeClosed />}
                        </IconContext.Provider>
                      </div>

                      {formik.errors.confirmPassword && (
                        <p className="err">{formik.errors.confirmPassword}</p>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <Button
                        className="login__btn"
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        disabled={submitLoader}
                        // id="admin-reportview-send-btn"
                        style={{
                          color: "white",
                        }}
                      >
                        {submitLoader ? (
                          <div
                            className="spinner-border text-light"
                            // style={{}}
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center">
            <div className="login__redirect" style={{ marginTop: "5px" }}>
              <p
                className="create__accText semiFont"
                style={{ fontSize: "16px" }}
              >
                Already have account?
              </p>
              <p className="create__acc" onClick={() => history.push("/")}>
                Login
              </p>
              
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AccountActivation;
