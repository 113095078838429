import { Button, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import "../assets/css/report.css";

import { FiArrowLeft, FiEdit, FiDownload, FiUpload } from "react-icons/fi";
import { AiTwotoneEdit, AiOutlineDelete } from "react-icons/ai";
// import { Formik } from "formik";
// import * as yup from "yup";
import { IconContext } from "react-icons";
// import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import Global from "../global";
import moment from "moment";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import emptyComment from "../assets/images/empty-comment.png";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const BlueSwitch = withStyles({
  switchBase: {
    color: "#0F61FD",
    "&$checked": {
      color: "#0F61FD",
    },
    "&$checked + $track": {
      backgroundColor: "#0F61FD",
    },
  },
  checked: {},
  track: {},
})(Switch);

const requiredField = <span style={{ color: "red" }}>*</span>;

const ReportView = () => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const history = useHistory();
  const location = useLocation();
  const hiddenFileInput = React.useRef(null);
  const [uploadedReports, setUploadedReports] = useState({});
  const [reportSwitch, setReportSwitch] = useState(false);
  const [reportData, setReportData] = useState("");
  const [uploader, setUploader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [refresh, setRefresh] = useState("");
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    async function fetchReportData() {
      try {
        const response = await axios.post(
          Global.base_url + "/user/report/get",
          {
            report_id: location.state.data.report_id,
          },
          config
        );
        // console.log(response);

        if (response.status === 200) {
          // setLoading(false);
          console.log("reports view", response.data);

          setReportData(response.data);
          try {
            const reportUpload = await axios.post(
              Global.base_url + "/user/report/document/list",
              {
                report_id: location.state.data.report_id,
              },
              config
            );

            if (reportUpload.status === 200) {
              console.log("uploaded reponse", reportUpload);

              if (reportUpload.data.combined.length) {
                setReportSwitch(true);
              }

              setUploadedReports(reportUpload.data);
            }
          } catch (error) {
            if (error.reportUpload) {
              if (error.reportUpload.status === 401) {
                return toast.error("Unable to get uploaded reports");
              }
              if (error.reportUpload.status === 500) {
                return toast.error("Internal server error");
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        // console.log("errror", error);
        if (error.response) {
          if (error.response.status === 401) {
            // console.log("admin product error", error);
            setLoading(true);
            return toast.error("Unable to get report");
          }
          if (error.response.status === 500) {
            // console.log("admin product error", error);
            setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchReportData();
    // console.log("AdminReportView", props);
  }, [refresh]);

  const downloadFile = async () => {
    try {
      const response = await axios.post(
        Global.base_url + "dashboard/admin/report/report_download",
        {
          test_id: reportData.test_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      // console.log(response);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report#${reportData.test_id}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return;
      }
    } catch (error) {
      if (error && error.response.status === 403)
        return toast.warning("No reports found");
      return console.log("failed");
    }
  };

  // console.log("reportData", reportData);

  const color2 =
    reportData && reportData.report.status === "Completed"
      ? "#03A33B"
      : reportData && reportData.report.status === "Awaiting Confirmation"
      ? "red"
      : reportData && reportData.report.status === "Scheduled"
      ? "#4059E2"
      : reportData && reportData.report.status === "Cancelled"
      ? "#818181"
      : "#EFA500";
  // let result =
  //   reportData.covid_status === 1
  //     ? "Positive"
  //     : reportData.covid_status === 0
  //     ? "Negative"
  //     : "Pending";
  // let color3 =
  //   reportData.covid_status === 1
  //     ? "#03A33B"
  //     : reportData.covid_status === 0
  //     ? "red"
  //     : "#EFA500";

  const downloadReport = async (doc) => {
    console.log("doc", doc);
    try {
      const response = await axios.post(
        Global.base_url + "/user/report/download",
        {
          report_id: location.state.data.report_id,
          doc_id: doc.doc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        console.log("reponse", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Report-${
            Object.keys(doc).includes("test_name") ||
            Object.keys(doc).includes("ul_test_name")
              ? doc.test_name || doc.ul_test_name
              : location.state.data.report_id
          }.pdf`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unable to download report");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };

  const downloadAllReport = async () => {
    setDownloadLoader(true);
    // console.log("doc", doc);
    try {
      const response = await axios.post(
        Global.base_url + "/user/report/downloadAll",
        {
          report_id: location.state.data.report_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        console.log("reponse", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `report-${location.state.data.report_id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        setDownloadLoader(false);
      }
    } catch (error) {
      setDownloadLoader(false);
      if (error.response && error.response.status === 401) {
        toast.error("Unable to download reports");
      }
      if (error.response && error.response.status === 500) {
        toast.error("Internal server error");
      }
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div
          className="main__container mb-2"
          style={{ backgroundColor: "#fff", minHeight: "90vh" }}
        >
          <div className="row  w-100 m-0">
            <div
              className="col-12 bg-white align-items-center pt-3 pb-3"
              style={{
                borderBottom: "1px solid #B3CDFF",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  history.push("/user/reports");
                }}
              >
                <IconContext.Provider value={{ size: "30px" }}>
                  <FiArrowLeft />
                </IconContext.Provider>
              </IconButton>
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "25px",
                  marginLeft: "5px",
                }}
                className="semiFont"
              >
                Back
              </p>
            </div>
            {reportData ? (
              <>
                <div className="w-100"></div>
                <div className="row  mt-3 p-3 w-100 ml-1">
                  <div className="col-xl-5 col-lg-5 col-12 ">
                    <div className="row mt-2">
                      <div className="col ">
                        <span className="admin-reportview-span  admin-reportview-heading-text ">
                          Report ID
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text">
                          RID{reportData.report.report_id}
                        </span>
                      </div>
                      <div className="col">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          Name
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text">
                          {/* {reportData.report_data
                    ? reportData.report_data.name
                    : "user"} */}
                          {reportData.report.member_name}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-md-6 col-sm-6">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          {reportData.report.package_name ? "Package" : "Test"}{" "}
                          Type
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text">
                          {reportData.report.type}
                        </span>
                      </div>
                      <div className="col-12  col-md-6 col-sm-6 mt-3 mt-sm-0">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          {reportData.report.package_name ? "Package" : "Test"}{" "}
                          Name
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text">
                          {reportData.report.package_name ||
                            reportData.report.test_name}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 col-md-12 col-sm-12">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          {reportData.report.package_name
                            ? "Test Inclusion"
                            : "Test Description"}{" "}
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text">
                          {reportData.report.test_inclusion ||
                            reportData.report.test_desc}
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-12 col-xl-8 col-lg-8   admin-reportview-btns mt-4 ">
                <Button
                  variant="contained"
                  id="admin-reportview-bill-btn"
                  style={{ border: "1px solid #B3CDFF", width: "100%" }}
                >
                  Bill PDF
                </Button>
              </div> */}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-12">
                    <div className="row">
                      <div className="col-12 mt-sm-1 mt-3">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          Test Center
                        </span>
                      </div>
                      <div className="col mt-1 ">
                        <span className="admin-reportview-span  admin-reportview-sub-text">
                          {reportData.report.lab_name}
                        </span>
                        <span className="admin-reportview-span  admin-reportview-sub-address">
                          {reportData.report.city +
                            "," +
                            reportData.report.state}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          Requested Date
                        </span>
                        <span className="admin-reportview-span admin-reportview-sub-text mt-1">
                          {moment(reportData.report.requested_date).format(
                            "DD-MM-YYYY h:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <span className="admin-reportview-span admin-reportview-heading-text">
                          Appointment Date
                        </span>
                        {reportData.report.appointment ? (
                          <span className="admin-reportview-span admin-reportview-sub-text mt-1">
                            {moment(reportData.report.appointment).format(
                              "DD-MM-YYYY h:mm A"
                            )}
                          </span>
                        ) : (
                          <span
                            className="admin-reportview-span admin-reportview-sub-text mt-1"
                            style={{ color: "red" }}
                          >
                            Not Schedule
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-12">
                    <div className="row">
                      <div className="col mt-2">
                        <p className="covid-status-label admin-reportview-heading-text">
                          Status
                        </p>
                        <p
                          className="admin-reportview-sub-text"
                          style={{ color: color2 }}
                        >
                          {reportData.report.status}
                        </p>
                      </div>
                    </div>

                    {/* {reportData.report.tracking_info !== null &&
                    reportData.report.type === "KIT DELIVERY" &&
                    (reportData.report.status === "Sample Processing" ||
                      reportData.report.status === "Completed") ? (
                      <div className="col mt-4 pl-0">
                        <p className="admin-reportview-heading-text">
                          Tracking Details
                        </p>
                        <p className="admin-reportview-sub-text">
                          {reportData.report.tracking_info}
                        </p>
                      </div>
                    ) : null} */}
                    {/* {reportData.report.type === "KIT DELIVERY" &&
                    reportData.report.status === "Awaiting Sample" ? (
                      <div className="col mt-4 pl-0">
               
                        <Formik
                          validateOnChange={false}
                          validateOnBlur={false}
                          initialValues={{
                            tracking_info:
                              reportData.report.tracking_info !== null &&
                              reportData.report.tracking_info
                                ? reportData.report.tracking_info
                                : "",
                          }}
                          validationSchema={yup.object().shape({
                            tracking_info: yup
                              .string()
                              .min(10, "Must be more than 10 letters")
                              .required("Tracking Info cannot be empty"),
                          })}
                          onSubmit={async (values, formik) => {


                            try {
                              const response = await axios.post(
                                Global.base_url +
                                  "/user/report/update/tracking",
                                {
                                  report_id: reportData.report.report_id,
                                  ...values,
                                },
                                config
                              );

                              // console.log(response);
                              if (response.status === 200) {
                                toast.success(
                                  "Tracking Details Updated Successfully"
                                );
                                return setRefresh(Date.now() + 1);
                              }

                              return;
                            } catch (error) {
                              if (
                                error.response &&
                                error.response.status === 401
                              ) {
                                return toast.error("Update failed");
                              }
                              if (
                                error.response &&
                                error.response.status === 500
                              ) {
                                return toast.error("Internal server error");
                              }
                            
                            }
                          }}
                        >
                          {(formik) => {
                            return (
                              <>
                                <div
                                  className="inputBox"
                                  style={{
                                    position: "relative",
                                    marginTop: 0,
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    htmlFor="address"
                                    className="admin-reportview-heading-text"
                                  >
                                    Tracking Info {requiredField}
                                  </label>
                                  <textarea
                                    id="tracking_info"
                                    rows="2"
                                    style={{
                                      border: formik.errors.tracking_info
                                        ? "1px solid red"
                                        : "1px solid #c3cad9",
                                      marginTop: "5px",
                                      width: "auto",
                                      resize: "none",
                                      borderRadius: "8px",
                                      padding: "10px",
                                      color: "#183b56",
                                      fontSize: "17px",
                                      fontFamily: "mediumFont",
                                    }}
                                    value={formik.values.tracking_info}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        "tracking_info",
                                        e.currentTarget.value.replace(
                                          /[^a-zA-Z0-9-_ ]/gi,
                                          ""
                                        )
                                      );
                                    }}
                                  />

                                  {formik.errors.tracking_info && (
                                    <p className="err">
                                      {formik.errors.tracking_info}
                                    </p>
                                  )}
                                </div>
                                <div className="form-group mt-3">
                                  <Button
                                    className="primary__btn"
                                    variant="contained"
                                    onClick={formik.handleSubmit}
                                  
                                    style={{
                                      padding: "8px 30px",
                                      color: "white",
                                    }}
                                  >
                                    {reportData.report.tracking_info !== null
                                      ? "Update"
                                      : "Submit"}
                                  </Button>
                                </div>
                              </>
                            );
                          }}
                        </Formik>
                      </div>
                    ) : null} */}
                  </div>
                </div>
                {uploadedReports &&
                (uploadedReports.individual.length ||
                  uploadedReports.combined.length) ? (
                  <div className="p-3 w-100">
                    <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-sm-row flex-column">
                      <p className="report__header mt-2">Reports</p>
                    </div>
                    <div className="col-12 admin-reportview-comments py-3 mt-2">
                      <>
                        <div
                          className={
                            uploadedReports &&
                            uploadedReports.individual.length &&
                            uploadedReports.combined.length === 0
                              ? "d-flex align-items-start align-items-sm-center justify-content-end flex-sm-row flex-column"
                              : "d-flex align-items-start align-items-sm-center justify-content-between flex-sm-row flex-column"
                          }
                        >
                          {uploadedReports.individual.length &&
                          uploadedReports.combined.length ? (
                            <FormControlLabel
                              // Needed by MaterialUI to create labels, like Santa, Grinch
                              control={
                                <BlueSwitch
                                  checked={reportSwitch} // boolean true/false
                                  onChange={() => {
                                    setReportSwitch((prev) => !prev);
                                  }} // "!" <-- lets the state flip, otherwise state flips once
                                />
                              }
                              label={reportSwitch ? "Combined" : "Individual"}
                            />
                          ) : null}
                          {!reportSwitch &&
                          uploadedReports.individual &&
                          uploadedReports.individual.filter(
                            (list) => list.doc_id
                          ).length >= 2 ? (
                            <Button
                              variant="contained"
                              className="secondary__btn  my-2 my-sm-0"
                              startIcon={
                                !downloadLoader && (
                                  <IconContext.Provider
                                    value={{
                                      color: "#1565d8",
                                      size: "20px",
                                    }}
                                  >
                                    <FiDownload />
                                  </IconContext.Provider>
                                )
                              }
                              onClick={() => {
                                downloadAllReport();
                              }}
                              style={{
                                padding: downloadLoader
                                  ? "8px 100px"
                                  : "8px 25px",
                                textAlign: "right",

                                // width: "150px",
                                color: "#1565d8",
                              }}
                              disabled={downloadLoader}
                            >
                              {downloadLoader ? (
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <p className="carbon__text">Download All</p>
                              )}
                            </Button>
                          ) : null}
                        </div>
                        <div
                          className=" d-flex flex-wrap justify-content-between mt-3"
                          style={{ gap: "10px" }}
                        >
                          {/* <> */}

                          {!reportSwitch && uploadedReports.individual ? (
                            uploadedReports.individual.map((report, i) => {
                              return (
                                <div
                                  className="admin-reportview-comments-box p-3 mb-0 uploaded__items"
                                  key={i}
                                >
                                  <div className="testItem__row d-flex justify-content-between">
                                    <div className="test__item">
                                      <p className="admin-reportview-heading-text">
                                        Test Name
                                      </p>
                                      <p className="admin-reportview-sub-text">
                                        {report.test_name ||
                                          report.ul_test_name}
                                      </p>
                                    </div>
                                    <p
                                      className="admin-reportview-heading-text"
                                      style={{
                                        color: "#03A33B",
                                      }}
                                    >
                                      Uploaded
                                    </p>
                                  </div>

                                  <div className="testItem__row d-flex align-items-center justify-content-end mt-2">
                                    <>
                                      <div className="item__row">
                                        <Tooltip
                                          title={"Download"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            style={{
                                              backgroundColor: "#1565d817",
                                            }}
                                            onClick={() => {
                                              downloadReport(report);
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "20px",
                                              }}
                                            >
                                              <FiDownload />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="admin-reportview-comments-box p-3 mb-0 uploaded__items"
                              // key={i}
                            >
                              <div className="testItem__row d-flex justify-content-between">
                                <div className="test__item">
                                  <p className="admin-reportview-heading-text">
                                    Package / Test
                                  </p>
                                  <p className="admin-reportview-sub-text">
                                    {(reportData.report &&
                                      reportData.report.package_name) ||
                                      (reportData.report &&
                                        reportData.report.test_name)}
                                  </p>
                                </div>
                                <p
                                  className="admin-reportview-heading-text"
                                  style={{
                                    color: uploadedReports.combined.length
                                      ? "#03A33B"
                                      : "#ffb100",
                                  }}
                                >
                                  {uploadedReports.combined.length
                                    ? "Uploaded"
                                    : "Inprogress"}
                                </p>
                              </div>

                              <div className="testItem__row d-flex align-items-center justify-content-end mt-2">
                                {/* {uploadedReports.combined.length ? ( */}
                                <>
                                  <div className="item__row">
                                    <Tooltip
                                      title={"Download"}
                                      placement="top-center"
                                    >
                                      <IconButton
                                        style={{
                                          backgroundColor: "#1565d817",
                                        }}
                                        onClick={() => {
                                          downloadReport(
                                            uploadedReports.combined[0]
                                          );
                                        }}
                                      >
                                        <IconContext.Provider
                                          value={{
                                            color: "#1565d8",
                                            size: "20px",
                                          }}
                                        >
                                          <FiDownload />
                                        </IconContext.Provider>
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </>
                                {/* ) : null} */}
                              </div>
                            </div>
                          )}
                          {/* </> */}
                        </div>
                      </>
                    </div>
                  </div>
                ) : null}

                <div className="p-3 w-100">
                  <div className="report__header mt-2">Comments</div>
                  <div className="col-12    admin-reportview-comments mt-2">
                    {reportData.comments.length ? (
                      reportData.comments.map((comment, i) => {
                        return (
                          <>
                            <div className="d-flex flex-sm-row flex-column justify-content-between admin-reportview-comments-box  mt-3 p-3">
                              <div className="d-flex flex-column">
                                <p className="item__title">{comment.title}</p>
                                <p className="item__subTitle mt-2">
                                  {comment.comment}
                                </p>
                              </div>
                              <p className="item__subTitle mt-sm-0 mt-2">
                                {comment.commented_datetime}
                              </p>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        <img src={emptyComment} alt="Empty-incoice" />
                        <p
                          style={{
                            color: "#D2D2D2",
                            padding: "20px",
                            fontSize: "20px",
                            fontFamily: "mediumFont",
                          }}
                        >
                          Comments will be availiable soon...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportView;
