import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import logo from "../assets/images/ampath.jpg";
import "../assets/css/signup.css";
import { Link } from "react-router-dom";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import LoginLogo from "../assets/images/healthmo.png";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { IconContext } from "react-icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";

import axios from "axios";
import Global from "../global";
import csc from "country-state-city";
import _ from "lodash";
import { Formik } from "formik";
import * as yup from "yup";
import "yup-phone";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Select from "react-select";
import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  //   KeyboardDatePicker,
  DatePicker,
  //   KeyboardDateTimePicker,
} from "@material-ui/pickers";
import LoginSideContent from "./LoginSideContent";

// styles for date picker and some typography
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: 0,
      backgroundColor: "#fff",

      "& .MuiInputBase-input": {
        padding: 15,
        color: "#183b56",
        backgroundColor: "#fff",

        "&::placeholder": {
          color: "#959ead",
        },
      },
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// steps
function getSteps() {
  return ["signup", "Verify", "Personal"];
}

const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  { value: "Other", label: "Other" },
];

export default function SignupSteps(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [user, setUser] = useState({});
  console.log("user data", user);
  const history = useHistory();

  const classes = useStyles();
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = async () => {
    try {
      const response = await axios.post(
        Global.base_url + "/user/signup/back",
        {
          email: user.email_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // redirect to sign up step  when back button is clicked
        setActiveStep((prevActiveStep) => {
          return prevActiveStep > 0 && prevActiveStep !== 2
            ? prevActiveStep - 1
            : activeStep;
        });
      }
    } catch (error) {
      console.log("error", error.response);
      if (error.response) {
        if (error.response.status === 401) {
          console.log("error data", error.response.data.msg);
        }
      }
    }
  };

  // reset handler goes to inital set(email and password)
  const handleReset = () => {
    setActiveStep(0);
  };

  //  setp :  email and password
  const SignupForm = () => {
    const [securetext, setSecuretext] = useState(true);
    const [loading, setLoading] = useState(false);
    // validation text section
    const validationSchema = yup.object({
      email_id: yup
        .string()
        .email("Invalid Email")
        .required("Email cannot be empty"),
      password: yup
        .string()
        .required("Password cannot be empty")
        .min(8, "password must have aleast 8 letters ")
        .notOneOf(
          ["password", "PASSWORD"],
          "Password does not contain 'password'"
        ),
      agreement: yup
        .boolean()
        .required()
        .oneOf([true], "Please accept terms and conditions"),
    });
    const initialValues = {
      email_id: user.email_id || "",
      password: user.password || "",
      agreement: "",
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (values, formik) => {
          setLoading(true);
          console.log(values);
          try {
            const response = await axios.post(
              Global.base_url + "/user/signup",
              {
                email: values.email_id,
                password: values.password,
              },
              Global.headers
            );
            setLoading(false);
            if (response.status === 200) {
              // If response success then goes to next step (otp)``
              console.log(response);
              setUser({ ...user, ...values });
              handleNext();
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                setLoading(false);
                formik.setFieldError("email_id", "Email already registered");
              }
            }
            setLoading(false);
            console.log("error", error.response.data);
          }
        }}
      >
        {(formik) => {
          return (
            <section className="signupform mt-2">
              <div className="container">
                <div
                  className="center__items"
                  style={{ flexDirection: "column" }}
                >
                  <div className="inputBox" style={{ position: "relative" }}>
                    <label htmlFor="email" className="semiFont login__label">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formik.values.email_id}
                      onChange={formik.handleChange("email_id")}
                      id="email_id"
                      aria-describedby="emailHelp"
                      placeholder="name@email.com"
                      style={{
                        border: formik.errors.email_id
                          ? "1px solid red"
                          : "1px solid #c3cad9",
                      }}
                    />

                    {/* shows error */}
                    {formik.errors.email_id && (
                      <p className="err">{formik.errors.email_id}</p>
                    )}
                  </div>

                  <div className="inputBox" style={{ position: "relative" }}>
                    <label htmlFor="password" className="semiFont login__label">
                      Password
                    </label>
                    <input
                      type={securetext ? "password" : "text"}
                      value={formik.values.password}
                      maxLength={15}
                      //password must be alphabetics, numbers and @(special character)
                      onChange={(e) => {
                        formik.setFieldValue(
                          "password",
                          e.currentTarget.value.replace(/[^a-zA-Z0-9@]/g, "")
                        );
                      }}
                      placeholder="password"
                      style={{
                        border: formik.errors.password
                          ? "1px solid red"
                          : "1px solid #c3cad9",
                      }}
                      id="password"
                    />
                    <div
                      className="password__icon"
                      style={{ marginTop: "-5px" }}
                      // eye visible and unvisibility check
                      onClick={(e) =>
                        securetext ? setSecuretext(false) : setSecuretext(true)
                      }
                    >
                      <IconContext.Provider
                        value={{
                          color: "#959EAD",
                          size: "20px",
                        }}
                      >
                        {securetext ? <VscEye /> : <VscEyeClosed />}
                      </IconContext.Provider>
                    </div>
                    {formik.errors.password && (
                      <p className="err">{formik.errors.password}</p>
                    )}
                  </div>
                  {/* </div> */}
                </div>
                <div className="row align-items-center justify-content-center mt-3">
                  <div className="col-12 col-md-8 col-sm-8  col-lg-12 col-xl-9 text-center">
                    <div
                      className="login__options"
                      style={{
                        marginTop: "15px",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.agreement}
                            onChange={formik.handleChange("agreement")}
                            name="I agree to "
                          />
                        }
                        label="I agree to "
                      />
                      <label
                        className=" agreement__label semiFont"
                        htmlFor="agreement"
                        style={{ color: "#5a718499", marginLeft: "-10px" }}
                      >
                        <Link className="login__forget">Terms</Link> and{" "}
                        <Link className="login__forget">Policy</Link>
                      </label>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      // onSumit
                      onClick={formik.handleSubmit}
                      disabled={!formik.values.agreement}
                      style={{
                        backgroundColor: formik.values.agreement
                          ? "#1565d8"
                          : "#C3CAD9",
                        marginTop: "5px",
                        padding: "10px 30px",
                        textTransform: "initial",
                      }}
                    >
                      {loading ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : activeStep === steps.length - 1 ? (
                        "Finish"
                      ) : (
                        "Signup"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          );
        }}
      </Formik>
    );
  };
  const Verification = () => {
    const [vloading, setvLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [show, setShow] = useState(false);

    // console.log("User DAta", user);
    const validationSchema = yup.object({
      otp: yup.string().min(6).required("Otp cannot be empty"),
    });
    const initialValues = {
      otp: "",
    };
    async function sendAgain() {
      setLoading1(true);
      try {
        const response = await axios.post(
          Global.base_url + "/user/signup/resend",
          {
            email: user.email_id,
          },
          Global.headers
        );
        if (response.status === 200) {
          setLoading1(false);
          setShow(true);
          let timer = setInterval(() => {
            setShow(false);
            clearInterval(timer);
          }, 5000);
          return;
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          return setLoading1(false);
        }
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (values, formik) => {
          setvLoading(true);
          console.log(values);
          try {
            const response = await axios.post(
              Global.base_url + "/user/signup/otp",
              {
                email: user.email_id,
                otp: parseInt(values.otp),
              },
              Global.headers
            );
            setvLoading(false);
            if (response.status === 200) {
              setUser({ ...user, ...values });
              handleNext();
            }
          } catch (error) {
            setvLoading(false);
            if (error.response) {
              if (error.response.status === 401) {
                formik.setFieldError("otp", error.response.data.msg);
              }
            }

            console.log("error", error);
          }
        }}
      >
        {(formik) => {
          return (
            <section className="signupform mt-2">
              <div className="container">
                <div
                  className="row align-items-center justify-content-center"
                  data-columns
                >
                  <div className="col-10 col-sm-11 col-md-8 col-lg-6 col-xl-5 text-center verification__header--text custom-verifi--header--text mb-2">
                    {show ? (
                      <p
                        style={{
                          color: "#03A33B",
                          fontFamily: "mediumFont",
                        }}
                      >
                        Message sent
                      </p>
                    ) : null}
                  </div>
                </div>
                <div
                  className="row align-items-center justify-content-center"
                  data-columns
                >
                  <p className="col-10 col-sm-11 col-md-8 col-lg-6 col-xl-8 text-center verification__header--text custom-verifi--header--text semiFont">
                    We just reached out to {user.email_id} with an activation
                    code. Just enter the code below to confirm your address
                  </p>
                </div>

                <div className="row align-items-center justify-content-center mt-2">
                  {/* <div className="col-9 col-sm-8 col-md-5 col-lg-4 col-xl-3 custom-verifi--input"> */}
                  <div className="inputBox" style={{ position: "relative" }}>
                    <label htmlFor="otp" className="semiFont login__label">
                      Enter OTP
                    </label>
                    <input
                      type="text"
                      maxLength={6}
                      value={formik.values.otp}
                      // number only validation
                      onChange={(e) =>
                        formik.setFieldValue(
                          "otp",
                          e.currentTarget.value.replace(/[^0-9]/g, "")
                        )
                      }
                      style={{
                        border: formik.errors.otp
                          ? "1px solid red"
                          : "1px solid #c3cad9",
                      }}
                      id="otp"
                      aria-describedby="otp"
                      placeholder="Enter OTP"
                    />
                    {formik.errors.otp && (
                      <p className="errorPosition">{formik.errors.otp}</p>
                    )}
                  </div>
                  {/* </div> */}
                </div>
                <div
                  className="center__items mt-3"
                  style={{ flexDirection: "column" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    className={"signup__button"}
                    style={{
                      backgroundColor: "#1565d8",
                    }}
                  >
                    {vloading ? (
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : activeStep === steps.length - 1 ? (
                      "Finish"
                    ) : (
                      "Verify Email"
                    )}
                  </Button>
                  <div
                    className="mt-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      className="create__accText semiFont"
                      style={{ fontSize: "16px" }}
                    >
                      Didn’t get email?
                      {/* <Link className="signup__link">Send again</Link> or{" "} */}
                      <Button
                        color="primary"
                        style={{
                          color: "#1565d8",
                          textTransform: "initial",
                          fontSize: "16px",
                          marginTop: "-4px",
                        }}
                        onClick={sendAgain}
                      >
                        {loading1 ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Send again"
                        )}
                      </Button>
                      or
                      {/* button for go back */}
                      <Button
                        color="primary"
                        style={{
                          color: "#1565d8",
                          textTransform: "initial",
                          fontSize: "16px",
                          marginTop: "-4px",
                        }}
                        onClick={handleBack}
                      >
                        Change email
                      </Button>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </section>
          );
        }}
      </Formik>
    );
  };

  const PersonalDetails = () => {
    // console.log("User DAta", user);
    const aadhaarRegex = "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$";
    const [securetext, setSecuretext] = useState(true);
    const [countries, setCountries] = useState([{ name: "Choose Country" }]);
    const [states, setStates] = useState([{ name: "Choose State" }]);
    const [cities, setCities] = useState([{ name: "Choose City" }]);
    const [cloading, setcLoading] = useState(false);
    const required = <span style={{ color: "red" }}>*</span>;
    useEffect(() => {
      const country = csc.getAllCountries();
      setCountries([...countries, ...country]);
    }, []);

    // validation text section
    const validationSchema = yup.object({
      firstname: yup.string().required("Firstname cannot be empty"),
      lastname: yup.string().required("Lastname cannot be empty"),
      country: yup.string().required("Please select Country"),
      gender: yup.object().required("Please select Gender").nullable(),
      state: yup.string().required("Please select State"),
      city: yup.string().required("Please select City"),
      dob: yup
        .date()
        .min("1925-01-01", "Date must be later than 01-01-1925")
        .max("2018-12-30", "Date must be earlier than 01-01-2018")
        .required("DOB is required")
        .nullable(),

      address1: yup.string().required("Ship Address 1 cannot be empty"),
      pincode: yup.string().required("Pincode cannot be empty"),
      phone: yup
        .string("Phone cannot be empty")
        .phone("Enter valid phone number")
        .required("Phone cannot be empty"),
    });
    const initialValues = {
      firstname: "",
      lastname: "",
      phone: "",
      gender: null,
      //   aadhaar: "",
      country: "",
      state: "",
      city: "",
      address1: "",
      //   address2: "",
      pincode: "",
      dob: null,
    };

    //city options when state is selected
    const getAllCities = (e) => {
      const state = states.find((s) => s.name === e.currentTarget.value);
      // console.log({ state });
      setCities([
        ...cities,
        ...csc.getCitiesOfState(state.countryCode, state.isoCode),
      ]);
    };

    // state options when country is selected
    const getAllStates = (e) => {
      const country = countries.find((c) => c.name === e.currentTarget.value);
      // console.log({ country });
      setStates([...states, ...csc.getStatesOfCountry(country.isoCode)]);
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        enableReinitialize
        onSubmit={async (values, formik) => {
          setcLoading(true);
          try {
            console.log(values);
            const response = await axios.post(
              Global.base_url + "/user/signup/completed",
              {
                email: user.email_id,
                firstname: values.firstname,
                lastname: values.lastname,
                gender: values.gender.value,
                phone: parseInt(values.phone),
                dob: moment(values.dob).format("YYYY-MM-DD"),
                country: values.country,
                state: values.state,
                city: values.city,
                address: values.address1,
                pincode: values.pincode,
              },
              Global.headers
            );
            // console.log(response);
            setcLoading(false);
            console.log("Success", response);
            if (response.status === 200) {
              return history.replace({
                pathname: "/",
                success: { isComplete: true },
              });
            }
          } catch (error) {
            console.log("error", error);
            if (error && error.response.status === 401) {
              formik.setFieldError("firstname", error.response.data.msg);
            }
            return setcLoading(false);
          }
        }}
      >
        {(formik) => {
          console.log(formik.errors);
          return (
            <section className="signupform mt-2">
              <div className="container mb-4">
                <div className="row">
                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="email"
                            className="semiFont login__label"
                          >
                            First Name{required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.firstname}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "firstname",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            style={{
                              border: formik.errors.firstname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                            }}
                            className="form-control signup__input"
                            id="firstname"
                            aria-describedby="firstname"
                            placeholder="first name"
                          />
                          {formik.errors.firstname && (
                            <p className="errorPosition">
                              {formik.errors.firstname}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="email"
                            className="semiFont login__label"
                          >
                            Last Name{required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.lastname}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "lastname",
                                e.currentTarget.value.replace(/[^a-zA-Z ]/g, "")
                              )
                            }
                            className="form-control signup__input"
                            style={{
                              border: formik.errors.lastname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                            }}
                            id="lastname"
                            aria-describedby="lastname"
                            placeholder="last name"
                          />
                          {formik.errors.lastname && (
                            <p className="errorPosition">
                              {formik.errors.lastname}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="dob"
                            className="semiFont login__label"
                          >
                            Date of Birth {required}
                          </label>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              inputVariant="filled"
                              disableFuture
                              openTo="year"
                              className={classes.root}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              format="DD/MM/YYYY"
                              views={["year", "month", "date"]}
                              value={formik.values.dob}
                              onChange={(date) =>
                                formik.setFieldValue("dob", date)
                              }
                              style={{
                                border: formik.errors
                                  ? formik.errors.dob
                                    ? "1px solid #dc3545"
                                    : "1px solid #c3cad9"
                                  : "1px solid #c3cad9",
                                // borderRadius: "5px",
                                marginTop: "5px",
                              }}
                              error={false}
                              helperText={null}
                              placeholder="DD/MM/YYYY"
                            />
                          </MuiPickersUtilsProvider>
                          {formik.errors.dob && (
                            <p className="errorPosition">{formik.errors.dob}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="product_typeoftest"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Gender {required}
                          </label>
                          <Select
                            value={formik.values.gender}
                            onChange={(p) => {
                              formik.setFieldValue("gender", p);
                              console.log("gender ", p.value);
                            }}
                            id="inputDropdown"
                            placeholder="Select Gender"
                            options={genderOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "7px",
                                border: formik.errors.gender
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.gender && (
                            <div className="err">
                              <p>{formik.errors.gender}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="phone"
                            className="semiFont login__label"
                          >
                            Phone{required}
                          </label>
                          <input
                            type="tel"
                            value={formik.values.phone}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "phone",
                                e.currentTarget.value.replace(/[^0-9]/g, "")
                              )
                            }
                            style={{
                              border: formik.errors.phone
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              color: "#183b56",
                            }}
                            className={"form-control signup__input"}
                            id="phone"
                            aria-describedby="phone"
                            placeholder="+1012345667"
                          />
                          {formik.errors.phone && (
                            <p className="errorPosition">
                              {formik.errors.phone}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="country"
                            className="semiFont login__label"
                          >
                            Country{required}
                          </label>
                          <select
                            className={"form-control signup__select"}
                            id="country"
                            style={{
                              border: formik.errors.country
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              color: "#183b56",
                            }}
                            placeholder="Choose Country"
                            // defaultValue={formik.values.country}
                            onChange={function (e) {
                              getAllStates(e);
                              formik.handleChange(e);
                            }}
                            value={formik.values.country}
                          >
                            {countries.map((c, i) => (
                              <option value={c.name} key={i}>
                                {c.name}
                              </option>
                            ))}
                          </select>
                          {formik.errors.country && (
                            <p className="errorPosition">
                              {formik.errors.country}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="state"
                            className="semiFont login__label"
                          >
                            State{required}
                          </label>
                          <select
                            className={"form-control signup__select"}
                            id="state"
                            style={{
                              border: formik.errors.state
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              color: "#183b56",
                            }}
                            placeholder="Choose State"
                            defaultValue={formik.values.state}
                            onChange={(e) => (
                              getAllCities(e), formik.handleChange(e)
                            )}
                            value={formik.values.state}
                          >
                            {states.map((c, i) => {
                              let ct = _.deburr(c.name);
                              return (
                                <option value={ct} key={i}>
                                  {ct}
                                </option>
                              );
                            })}
                          </select>
                          {formik.errors.state && (
                            <p className="errorPosition">
                              {formik.errors.state}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="city"
                            className="semiFont login__label"
                          >
                            City{required}
                          </label>
                          <select
                            className={"form-control signup__select"}
                            id="city"
                            placeholder="Choose City"
                            // defaultValue={formik.values.city}
                            onChange={formik.handleChange("city")}
                            value={formik.values.city}
                            style={{
                              border: formik.errors.city
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                            }}
                          >
                            {cities.map((c, i) => {
                              let ct = _.deburr(c.name);
                              return (
                                <option value={ct} key={i}>
                                  {ct}
                                </option>
                              );
                            })}
                          </select>
                          {formik.errors.city && (
                            <p className="errorPosition">
                              {formik.errors.city}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="row justify-content-center">
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input ">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="pincode"
                            className="semiFont login__label"
                          >
                            Address {required}
                          </label>
                          <textarea
                            type="text"
                            value={formik.values.address1}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "address1",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              )
                            }
                            rows="3"
                            spellCheck={false}
                            className={"form-control signup__input"}
                            id="address1"
                            aria-describedby="address1"
                            placeholder="Address"
                            style={{
                              border: formik.errors.address1
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              padding: "10px",
                              marginTop: "5px",
                              resize: "none",
                            }}
                          />
                          {formik.errors.address1 && (
                            <p className="errorPosition">
                              {formik.errors.address1}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-9 col-xl-6 col-lg-6 col-md-6 col-sm-8 custom-personal--input ">
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label
                            htmlFor="pincode"
                            className="semiFont login__label"
                          >
                            Pincode{required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.pincode}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "pincode",
                                e.currentTarget.value.replace(/[^\w\s]/gi, "")
                              )
                            }
                            className={"form-control signup__input"}
                            id="pincode"
                            aria-describedby="pincode"
                            placeholder="68788"
                            style={{
                              border: formik.errors.pincode
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                            }}
                          />
                          {formik.errors.pincode && (
                            <p className="errorPosition">
                              {formik.errors.pincode}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center justify-content-center mt-3">
                  <div className="col-6 text-center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={formik.handleSubmit}
                      className={"signup__button"}
                      style={{
                        backgroundColor: "#1565d8",

                        // marginTop: "10px",
                      }}
                      //   disabled={true}
                    >
                      {cloading ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          );
        }}
      </Formik>
    );
  };
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <SignupForm />;
      case 1:
        return <Verification />;
      case 2:
        return <PersonalDetails />;
      default:
        return "Unknown stepIndex";
    }
  }
  return (
    <main className="login__container">
      <section
        // className="container"
        style={{
          backgroundColor: "#fff",
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div> */}
        <div className="container">
          <div className="col text-center">
            <img src={LoginLogo} alt=" logo" className="login__logo" />
            <h2
              className="hkBoldFont login__header"
              style={{ marginTop: "10px" }}
            >
              Sign up
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel onClick={handleBack}></StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                  </div>
                ) : (
                  <div>
                    <Typography
                      className={classes.instructions}
                      component={"span"}
                    >
                      {getStepContent(activeStep)}
                      {/* <PersonalDetails /> */}
                      {/* <Verification /> */}
                    </Typography>
                    <div>
                      {/* <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <section className="signup__header"> */}
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="login__redirect" style={{ marginTop: "5px" }}>
                <p
                  className="create__accText semiFont"
                  style={{ fontSize: "16px" }}
                >
                  Already have account?
                </p>
                <p className="create__acc" onClick={() => history.push("/")}>
                  Login
                </p>
                {/* <p
                    className="signup_header__content"
                    style={{ fontSize: "24px" }}
                  >
                    3 Step Sign up Process
                  </p> */}
                {/* </div>
              </div> */}
              </div>
            </div>
          </div>
          {/* </section> */}
        </div>
        {/* </div> */}
      </section>
      <LoginSideContent />
    </main>
  );
}
