import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import test from "../assets/test.pdf";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/billview.css";
import moment from "moment";
// import { ProgressBar } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { ImEqualizer2 } from "react-icons/im";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdArrowDropright,
} from "react-icons/io";
import axios from "axios";
import Global from "../global";
import { FiArrowLeft } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { FaFileInvoiceDollar } from "react-icons/fa";

// import { savePDF } from "@progress/kendo-react-pdf";
// import html2pdf from "html2pdf.js";
import TablePagination from "@material-ui/core/TablePagination";
import { IconContext } from "react-icons";
import Select from "react-select";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@material-ui/core";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfViewer = () => {
  const history = useHistory();
  const [file, setFile] = useState(test);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <main
      className="safe__container center__items pb-3"
      style={{ alignItems: "flex-start" }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />

      <section
        style={{ width: "95%", backgroundColor: "#fff", position: "relative" }}
      >
        <div
          className="col-12 bg-white align-items-center pt-3 pb-3 justify-content-between"
          style={{
            borderBottom: "1px solid #B3CDFF",
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: "68px",
            zIndex: 10001,
          }}
        >
          <div className="d-flex align-items-center">
            <IconButton
              onClick={() => {
                history.push("/user/invoices");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              Back
            </p>
          </div>
          <Button
            variant="contained"
            className="primary__btn"
            id="admin-reportview-download-btn"
            style={{
              padding: "10px 25px",

              color: "#fff",
            }}
            // onClick={() => createPdf(pdfView.current, billView.data.bill_id)}
          >
            Download
          </Button>
        </div>
        <div
          className="center__items"
          style={{
            flexDirection: "column",
          }}
        >
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: numPages }, (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
            {/* <Page pageNumber={pageNumber} /> */}
          </Document>
          {/* <p className="mb-4 floating__content">
            Page {pageNumber} of {numPages}
          </p> */}
        </div>
      </section>
    </main>
  );
};

export default PdfViewer;
