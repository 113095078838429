import React, { createContext, useState } from "react";
import User from "../assets/images/user.png";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [image_url, setImage_url] = useState(User);

  return (
    <GlobalContext.Provider value={{ image_url, setImage_url }}>
      {children}
    </GlobalContext.Provider>
  );
};
