import React, { useState, useContext, useEffect, useCallback } from "react";
import "../assets/css/sidebar.css";
import { CgMenu } from "react-icons/cg";
import { RiNotification3Line } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import {
  RiBarChart2Line,
  RiSettings2Line,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import { BsFileEarmarkMedical, BsCart3 } from "react-icons/bs";
import { GoFile, GoPackage } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { IconContext } from "react-icons";
import LoginLogo from "../assets/images/healthmo.png";
import User from "../assets/images/user.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  Link,
  useHistory,
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import Labs from "./Labs";
import Home from "./Home";
import Invoices from "./Invoices";
import Members from "./Members";
import Reports from "./Reports";
import Settings from "./Settings";
import { Modal } from "react-bootstrap";
import Book from "./Book";
import Cart from "./Cart";
import Billing from "./Billing";
import ReportView from "./ReportView";
import InvoiceView from "./InvoiceView";
import PdfViewer from "./PdfViewer";
import { GlobalContext } from "../context/Context";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Global from "../global";
import { Minimize } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "#fff",
  },

  itemRow: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  iconActive: {
    fontSize: "20px",
  },
  itemContainer: {
    // width: "92%",
    padding: "15px 5px",
    margin: "0 auto",
    "&:hover": {
      color: "rgba(23, 23, 37, .7)",
    },
    // borderRadius: "5px",
    // marginTop: "10px",
  },
}));

const Sidebar = () => {
  const history = useHistory();
  const classes = useStyles();
  let { path } = useRouteMatch();
  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const activeSidebar = localStorage.getItem("active-sidebar");
  const [openSidebar, setOpenSidebar] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(Number(activeSidebar));
  const [logOutModal, setLogoutModal] = useState(false);
  const [minimizeSidebar, setMinimizeSidebar] = useState(
    sidebarCollapsed ? false : true
  );
  const { image_url, setImage_url } = useContext(GlobalContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 }); // true

  console.log("isTabletOrMobile", isTabletOrMobile);

  useEffect(() => {
    async function fetchUserImage() {
      try {
        const imageResponse = await axios.post(
          Global.base_url + "/user/profile/image/load",
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        );
        // console.log(response);

        if (imageResponse.status === 200) {
          // setImage_url(imageResponse.data);
          let imageBlob = URL.createObjectURL(imageResponse.data);
          console.log("image get reponse", imageBlob);
          // toast.error("Image success");
          setImage_url(imageBlob);
        }
      } catch (error) {
        if (error.imageResponse) {
          if (error.imageResponse.status === 401) {
            toast.error("Unable to get User image");
            console.log("admin user error", error);
            return;
          }
        }
      }
    }

    fetchUserImage();
  }, []);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    localStorage.setItem("active-sidebar", index);
    if (isTabletOrMobile) setMinimizeSidebar(false);
  };
  const handleMinimize = () => {
    if (minimizeSidebar) {
      setMinimizeSidebar(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setMinimizeSidebar(true);
    localStorage.removeItem("sidebar-collapsed");
  };

  const VerticaLogoutModal = () => {
    const handleLogout = () => {
      localStorage.clear();
      window.location.href = "/";
    };

    return (
      <Modal
        show={logOutModal}
        onHide={() => setLogoutModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter boldFont">
            Log Out
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5 px-4 mediumFont">
          Do you want to Logout ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleLogout()}
            style={{
              backgroundColor: "#1565d8",
              width: "100px",
              textTransform: "initial",
              boxShadow: "0 2px 5px #00000029",
              color: "#fff",
              marginRight: "15px",
            }}
          >
            Confirm
          </Button>
          <Button
            // id="modal__submitBtn"
            onClick={() => setLogoutModal(false)}
            style={{
              width: "100px",
              color: "#1565d8",
              border: "1px solid #1565d8",
              textTransform: "initial",
              boxShadow: "0 2px 5px #00000029",
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <VerticaLogoutModal
        show={logOutModal}
        onHide={() => setLogoutModal(false)}
      />

      <main className="sidebar__container">
        <header className="sidebar__headerWrapper">
          <section className="sidebar__header">
            <div className="sidebar__headerContainer">
              <div className="sidebar__logoSection">
                {/* <div className="sidebar__menu">
                  <IconButton onClick={() => setOpenSidebar(!openSidebar)}>
                    <IconContext.Provider
                      value={{
                        color: "#444",
                        size: "35px",
                      }}
                    >
                      <CgMenu />
                    </IconContext.Provider>
                  </IconButton>
                </div> */}
                <div className="sidebar__menu">
                  <IconButton onClick={handleMinimize}>
                    <IconContext.Provider
                      value={{
                        color: "#444",
                        size: "35px",
                      }}
                    >
                      <CgMenu />
                    </IconContext.Provider>
                  </IconButton>
                </div>
                <img
                  src={LoginLogo}
                  alt="health logo"
                  className="sidebar__logo"
                />
              </div>
              <div className="sidebar__userDetails">
                <Link
                  className="cart__logoContainer"
                  to="/user/cart"
                  // style={{ marginRight: "10px" }}
                >
                  <IconButton
                  // onClick={() => {
                  //   // console.log("sfkjgsdkgsk", history);
                  //   // history.push("/user/cart");
                  //   // window.location.reload();
                  // }}
                  >
                    <IconContext.Provider
                      value={{
                        color: "#898989",
                        size: "30px",
                      }}
                    >
                      <BsCart3 />
                    </IconContext.Provider>
                  </IconButton>
                </Link>
                {/* <Link to="/user/cart">sgdfkgs</Link> */}
                <IconButton>
                  <IconContext.Provider
                    value={{
                      color: "#898989",
                      size: "30px",
                    }}
                  >
                    <RiNotification3Line />
                  </IconContext.Provider>
                </IconButton>
                <div className="sidebar__userDropdown">
                  <div className="user__avatarContainer">
                    <img
                      src={image_url}
                      alt="userAvator"
                      className="user__avatar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>

        <section
          className={
            minimizeSidebar || openSidebar
              ? "sidebar__section"
              : "sidebar__section--mini"
          }
        >
          <div className="sidebar__list">
            <List className="itemRow">
              <ListItem
                button
                component={NavLink}
                selected={selectedIndex === 0}
                to={`/user/dashboard`}
                className={classes.itemContainer}
                style={{
                  backgroundColor:
                    selectedIndex === 0 ? "rgba(0, 98, 255, .10)" : "#fff",
                  borderLeft:
                    selectedIndex === 0
                      ? "3px solid rgba(0, 88, 255, 1)"
                      : "3px solid #fff",
                }}
                onClick={(event) => {
                  handleListItemClick(event, 0);

                  //   setSidebar(!sidebar);
                }}
              >
                <div className="sidebar__icons">
                  <IconContext.Provider
                    value={{
                      color:
                        selectedIndex === 0 ? "rgba(0, 88, 255, 1)" : "#777",
                      size: "30px",
                    }}
                  >
                    <AiOutlineHome />
                  </IconContext.Provider>
                </div>
                {/* </div> */}
                <p
                  className={
                    selectedIndex === 0
                      ? "sidebar__title--scaled"
                      : "sidebar__title"
                  }
                  style={{
                    color:
                      selectedIndex === 0
                        ? "rgba(0, 88, 255, 1)"
                        : "rgba(23, 23, 37, .7)",
                  }}
                >
                  Home
                </p>
              </ListItem>

              <ListItem
                button
                component={NavLink}
                selected={selectedIndex === 1}
                to={`/user/reports`}
                // activeClassName="active__icon"
                className={classes.itemContainer}
                style={{
                  backgroundColor:
                    selectedIndex === 1 ? "rgba(0, 98, 255, .10)" : "#fff",
                  borderLeft:
                    selectedIndex === 1
                      ? "3px solid rgba(0, 88, 255, 1)"
                      : "3px solid #fff",
                }}
                onClick={(event) => {
                  handleListItemClick(event, 1);

                  //   setSidebar(!sidebar);
                }}
              >
                {/* <div
                    className={
                      selectedIndex === 0 ? "icon__wrapper" : "icon__normal"
                    }
                  > */}
                <div className="sidebar__icons">
                  <IconContext.Provider
                    value={{
                      color:
                        selectedIndex === 1 ? "rgba(0, 88, 255, 1)" : "#777",
                      size: "30px",
                    }}
                  >
                    <GoFile />
                  </IconContext.Provider>
                </div>
                {/* </div> */}
                <p
                  className={
                    selectedIndex === 1
                      ? "sidebar__title--scaled"
                      : "sidebar__title"
                  }
                  style={{
                    color:
                      selectedIndex === 1
                        ? "rgba(0, 88, 255, 1)"
                        : "rgba(23, 23, 37, .7)",
                  }}
                >
                  Reports
                </p>
              </ListItem>

              <ListItem
                button
                component={NavLink}
                selected={selectedIndex === 2}
                to={`/user/invoices`}
                className={classes.itemContainer}
                style={{
                  backgroundColor:
                    selectedIndex === 2 ? "rgba(0, 98, 255, .10)" : "#fff",
                  borderLeft:
                    selectedIndex === 2
                      ? "3px solid rgba(0, 88, 255, 1)"
                      : "3px solid #fff",
                }}
                onClick={(event) => {
                  // sortedData = [];
                  handleListItemClick(event, 2);

                  //   history.push("/student/calendar");
                  //   setSidebar(!sidebar);
                }}
              >
                <div className="sidebar__icons">
                  <IconContext.Provider
                    value={{
                      color:
                        selectedIndex === 2 ? "rgba(0, 88, 255, 1)" : "#777",
                      size: "30px",
                    }}
                  >
                    <BsFileEarmarkMedical />
                  </IconContext.Provider>
                </div>
                <p
                  className={
                    selectedIndex === 2
                      ? "sidebar__title--scaled"
                      : "sidebar__title"
                  }
                  style={{
                    color:
                      selectedIndex === 2
                        ? "rgba(0, 88, 255, 1)"
                        : "rgba(23, 23, 37, .7)",
                  }}
                >
                  Invoices
                </p>
              </ListItem>

              <ListItem
                button
                component={NavLink}
                selected={selectedIndex === 3}
                to={`/user/members`}
                className={classes.itemContainer}
                style={{
                  backgroundColor:
                    selectedIndex === 3 ? "rgba(0, 98, 255, .10)" : "#fff",
                  borderLeft:
                    selectedIndex === 3
                      ? "3px solid rgba(0, 88, 255, 1)"
                      : "3px solid #fff",
                }}
                onClick={(event) => {
                  // sortedData = [];
                  handleListItemClick(event, 3);

                  //   history.push("/student/calendar");
                  //   setSidebar(!sidebar);
                }}
              >
                <div className="sidebar__icons">
                  <IconContext.Provider
                    value={{
                      color:
                        selectedIndex === 3 ? "rgba(0, 88, 255, 1)" : "#777",
                      size: "30px",
                    }}
                  >
                    <FiUsers />
                  </IconContext.Provider>
                </div>
                <p
                  className={
                    selectedIndex === 3
                      ? "sidebar__title--scaled"
                      : "sidebar__title"
                  }
                  style={{
                    color:
                      selectedIndex === 3
                        ? "rgba(0, 88, 255, 1)"
                        : "rgba(23, 23, 37, .7)",
                  }}
                >
                  Members
                </p>
              </ListItem>
              <ListItem
                button
                component={NavLink}
                selected={selectedIndex === 4}
                to={`/user/settings`}
                className={classes.itemContainer}
                style={{
                  backgroundColor:
                    selectedIndex === 4 ? "rgba(0, 98, 255, .10)" : "#fff",
                  borderLeft:
                    selectedIndex === 4
                      ? "3px solid rgba(0, 88, 255, 1)"
                      : "3px solid #fff",
                }}
                onClick={(event) => {
                  // sortedData = [];
                  handleListItemClick(event, 4);

                  //   history.push("/student/calendar");
                  //   setSidebar(!sidebar);
                }}
              >
                <div className="sidebar__icons">
                  <IconContext.Provider
                    value={{
                      color:
                        selectedIndex === 4 ? "rgba(0, 88, 255, 1)" : "#777",
                      size: "30px",
                    }}
                  >
                    <RiSettings2Line />
                  </IconContext.Provider>
                </div>
                <p
                  className={
                    selectedIndex === 4
                      ? "sidebar__title--scaled"
                      : "sidebar__title"
                  }
                  style={{
                    color:
                      selectedIndex === 4
                        ? "rgba(0, 88, 255, 1)"
                        : "rgba(23, 23, 37, .7)",
                  }}
                >
                  Settings
                </p>
              </ListItem>
              <Button
                variant="contained"
                color="secondary"
                className="logout__btn"
                type="submit"
                onClick={() => setLogoutModal(true)}
                startIcon={
                  <IconContext.Provider
                    value={{
                      color: "#777",
                      size: "30px",
                    }}
                  >
                    <RiLogoutBoxRLine />
                  </IconContext.Provider>
                }
              >
                <p className="sidebar__title">Logout</p>
              </Button>
            </List>
          </div>
        </section>

        {/* </main> */}
        <section
          className={
            minimizeSidebar ? "safe__container" : "safe__container--mini"
          }
        >
          <Switch>
            <Route exact path={`${path}/dashboard`}>
              <Home />
            </Route>

            <Route path={`${path}/reports`}>
              <Reports />
            </Route>

            <Route path={`${path}/invoices`}>
              <Invoices />
            </Route>
            <Route path={`${path}/members`}>
              <Members />
            </Route>
            <Route path={`${path}/settings`}>
              <Settings />
            </Route>
            <Route path={`${path}/book`}>
              <Book />
            </Route>
            <Route path={`${path}/cart`}>
              <Cart />
            </Route>
            <Route path={`${path}/checkout`}>
              <Billing />
            </Route>
            <Route path={`${path}/report-view`}>
              <ReportView />
            </Route>
            <Route path={`${path}/invoice-view`}>
              <InvoiceView />
            </Route>
          </Switch>
          {/* </section> */}
          {/* </section> */}
        </section>
      </main>
    </Router>
  );
};

export default Sidebar;
